<template>
  <v-container>
    <v-row>
      <!-- Warehouse sales column -->
      <v-col cols="4">
        <v-card>
          <v-toolbar dense color="primary">
            <v-toolbar-title>Warehouse sale</v-toolbar-title>
          </v-toolbar>

          <v-row class="ml-4 mt-0 mr-4 mb-0">
            <v-col cols="4" class="pb-0">
              <v-text-field
                :value="sales.warehouse_sales.count_mtd"
                label="MTD count"
                readonly
                :loading="!!salesLoading"
              ></v-text-field>
            </v-col>

            <v-col cols="8" class="pb-0">
              <v-text-field
                :value="formatMoney(sales.warehouse_sales.extended_price_mtd)"
                label="MTD extended price"
                readonly
                :loading="!!salesLoading"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="ml-4 mt-0 mr-4">
            <v-col cols="4" class="pt-0">
              <v-text-field
                :value="sales.warehouse_sales.count_qtd"
                label="QTD count"
                readonly
                :loading="!!salesLoading"
              ></v-text-field>
            </v-col>

            <v-col cols="8" class="pt-0">
              <v-text-field
                :value="formatMoney(sales.warehouse_sales.extended_price_qtd)"
                label="QTD extended price"
                readonly
                :loading="!!salesLoading"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="ml-4 mt-0 mr-4">
            <v-col cols="4" class="pt-0">
              <v-text-field
                :value="sales.warehouse_sales.count_ytd"
                label="YTD count"
                readonly
                :loading="!!salesLoading"
              ></v-text-field>
            </v-col>

            <v-col cols="8" class="pt-0">
              <v-text-field
                :value="formatMoney(sales.warehouse_sales.extended_price_ytd)"
                label="YTD extended price"
                readonly
                :loading="!!salesLoading"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- Category sales column -->
      <v-col cols="4">
        <v-card>
          <v-toolbar dense color="primary">
            <v-toolbar-title>Category sale</v-toolbar-title>
          </v-toolbar>

          <v-row class="ml-4 mt-0 mr-4 mb-0">
            <v-col cols="4" class="pb-0">
              <v-text-field
                :value="sales.category_sales.count_mtd"
                label="MTD count"
                readonly
                :loading="!!salesLoading"
              ></v-text-field>
            </v-col>

            <v-col cols="8" class="pb-0">
              <v-text-field
                :value="formatMoney(sales.category_sales.extended_price_mtd)"
                label="MTD extended price"
                readonly
                :loading="!!salesLoading"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="ml-4 mt-0 mr-4">
            <v-col cols="4" class="pt-0">
              <v-text-field
                :value="sales.category_sales.count_qtd"
                label="QTD count"
                readonly
                :loading="!!salesLoading"
              ></v-text-field>
            </v-col>

            <v-col cols="8" class="pt-0">
              <v-text-field
                :value="formatMoney(sales.category_sales.extended_price_qtd)"
                label="QTD extended price"
                readonly
                :loading="!!salesLoading"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="ml-4 mt-0 mr-4">
            <v-col cols="4" class="pt-0">
              <v-text-field
                :value="sales.category_sales.count_ytd"
                label="YTD count"
                readonly
                :loading="!!salesLoading"
              ></v-text-field>
            </v-col>

            <v-col cols="8" class="pt-0">
              <v-text-field
                :value="formatMoney(sales.category_sales.extended_price_ytd)"
                label="YTD extended price"
                readonly
                :loading="!!salesLoading"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- Foodservice sales column -->
      <v-col cols="4">
        <v-card>
          <v-toolbar dense color="primary">
            <v-toolbar-title>Foodservice sale</v-toolbar-title>
          </v-toolbar>

          <v-row class="ml-4 mt-0 mr-4 mb-0">
            <v-col cols="4" class="pb-0">
              <v-text-field
                :value="sales.foodservice_sales.count_mtd"
                label="MTD count"
                readonly
                :loading="!!salesLoading"
              ></v-text-field>
            </v-col>

            <v-col cols="8" class="pb-0">
              <v-text-field
                :value="formatMoney(sales.foodservice_sales.extended_price_mtd)"
                label="MTD extended price"
                readonly
                :loading="!!salesLoading"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="ml-4 mt-0 mr-4">
            <v-col cols="4" class="pt-0">
              <v-text-field
                :value="sales.foodservice_sales.count_qtd"
                label="QTD count"
                readonly
                :loading="!!salesLoading"
              ></v-text-field>
            </v-col>

            <v-col cols="8" class="pt-0">
              <v-text-field
                :value="formatMoney(sales.foodservice_sales.extended_price_qtd)"
                label="QTD extended price"
                readonly
                :loading="!!salesLoading"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="ml-4 mt-0 mr-4">
            <v-col cols="4" class="pt-0">
              <v-text-field
                :value="sales.foodservice_sales.count_ytd"
                label="YTD count"
                readonly
                :loading="!!salesLoading"
              ></v-text-field>
            </v-col>

            <v-col cols="8" class="pt-0">
              <v-text-field
                :value="formatMoney(sales.foodservice_sales.extended_price_ytd)"
                label="YTD extended price"
                readonly
                :loading="!!salesLoading"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-toolbar dense color="primary">
            <v-toolbar-title>Warehouse data</v-toolbar-title>
          </v-toolbar>
        </v-card>
      </v-col>
    </v-row>

    <div>
      <v-row>
        <v-col cols="2" class="ml-4 mt-0 mr-4">
          <v-text-field
            dense
            :value="warehouse.id"
            readonly
            label="Warehouse ID"
          ></v-text-field>
        </v-col>

        <v-col cols="8" class="ml-4 mt-0 mr-4">
          <v-text-field
            dense
            :value="warehouse.name"
            readonly
            label="Name"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="1" class="ml-4 mt-0 mr-4">
          <v-text-field
            dense
            :value="warehouse.house_number"
            readonly
            label="House"
          ></v-text-field>
        </v-col>

        <v-col cols="3" class="ml-4 mt-0 mr-4">
          <v-text-field
            dense
            :value="warehouse.street"
            readonly
            label="Street"
          ></v-text-field>
        </v-col>

        <v-col cols="3" class="ml-4 mt-0 mr-4">
          <v-text-field
            dense
            :value="warehouse.street_2"
            readonly
            label="Street 2"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4" class="ml-4 mt-0 mr-4">
          <v-text-field
            dense
            :value="warehouse.city"
            readonly
            label="City"
          ></v-text-field>
        </v-col>

        <v-col cols="1" class="ml-4 mt-0 mr-4">
          <v-text-field
            dense
            :value="warehouse.state"
            readonly
            label="State"
          ></v-text-field>
        </v-col>

        <v-col cols="1" class="ml-4 mt-0 mr-4">
          <v-text-field
            dense
            :value="warehouse.zip"
            readonly
            label="ZIP"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3" class="ml-4 mt-0 mr-4">
          <v-text-field
            dense
            :value="vendorLocation"
            readonly
            label="Vendor location"
          ></v-text-field>
        </v-col>

        <v-col cols="3" class="ml-4 mt-0 mr-4">
          <v-text-field
            dense
            :value="marketSegment"
            readonly
            label="Market segment"
          ></v-text-field>
        </v-col>

        <v-col cols="3" class="ml-4 mt-0 mr-4">
          <v-text-field
            dense
            :value="buyingSubgroup"
            readonly
            label="Subgroup"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3" class="ml-4 mt-0 mr-4">
          <v-text-field
            dense
            :value="distributor"
            readonly
            label="Distributor"
          ></v-text-field>
        </v-col>

        <v-col cols="3" class="ml-4 mt-0 mr-4">
          <v-text-field
            dense
            :value="mwcBrokerSalesTerritory"
            readonly
            label="MWC broker sales territory"
          ></v-text-field>
        </v-col>

        <v-col cols="3" class="ml-4 mt-0 mr-4">
          <v-text-field
            dense
            :value="marsDirectSalesTerritory"
            readonly
            label="Direct sales territory"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import api from "@/services/api";
import { formatMoney, formatDate, formatNumber } from "@/lib/utils";

export default {
  data() {
    return {
      warehouseLoading: false,
      salesLoading: false,

      warehouse: null,
      sales: null,
    };
  },

  created() {
    this.warehouse = {
      id: null,
      zip: null,
      city: null,
      name: null,
      state: null,
      street: null,
      created: null,
      street_2: null,
      house_number: null,
      store_number: null,
      market_segment_id: { key: null, value: null },
      buying_subgroup_id: { key: null, value: null },
      vendor_location_id: { key: null, value: null },
      mars_distributor_id: { key: null, value: null },
      mwc_broker_sales_territory_id: { key: null, value: null },
      mars_direct_sales_territory_id: {
        key: null,
        value: null,
      },
    };

    this.sales = {
      category_sales: {
        count_mtd: null,
        count_qtd: null,
        count_ytd: null,
        extended_price_mtd: null,
        extended_price_qtd: null,
        extended_price_ytd: null,
      },
      warehouse_sales: {
        count_mtd: null,
        count_qtd: null,
        count_ytd: null,
        extended_price_mtd: null,
        extended_price_qtd: null,
        extended_price_ytd: null,
      },
      foodservice_sales: {
        count_mtd: null,
        count_qtd: null,
        count_ytd: null,
        extended_price_mtd: null,
        extended_price_qtd: null,
        extended_price_ytd: null,
      },
    };
  },

  methods: {
    formatMoney(value) {
      // stupid, but here so the linter doesn't complain
      return formatMoney(value);
    },

    formatNumber(value) {
      // stupid, but here so the linter doesn't complain
      return formatNumber(value);
    },

    formatDate(value) {
      // stupid, but here so the linter doesn't complain
      return formatDate(value);
    },

    getWarehouseInfo(warehouseID) {
      this.warehouseLoading = true;
      api
        .callDBFunc("etl-2", "get_warehouse", { id: warehouseID })
        .then((response) => {
          this.warehouseLoading = false;
          const r = response.data.rows;
          if (r) {
            this.warehouse = r[0];
          }
        })
        .catch(() => {
          this.warehouseLoading = false;
        });
    },

    getSalesInfo(warehouseID) {
      this.salesLoading = true;
      api
        .callDBFunc("etl-2", "get_warehouse_sales", { id: warehouseID })
        .then((response) => {
          this.salesLoading = false;
          const r = response.data.rows;
          if (r) {
            this.sales = r[0];
          }
        })
        .catch(() => {
          this.salesLoading = false;
        });
    },
  },

  computed: {
    marketSegment() {
      return this.warehouse.market_segment_id.key
        ? `${this.warehouse.market_segment_id.key} - ${this.warehouse.market_segment_id.value}`
        : "";
    },

    buyingSubgroup() {
      return this.warehouse.buying_subgroup_id.key
        ? `${this.warehouse.buying_subgroup_id.key} - ${this.warehouse.buying_subgroup_id.value}`
        : "";
    },

    vendorLocation() {
      return this.warehouse.vendor_location_id.key
        ? `${this.warehouse.vendor_location_id.key} - ${this.warehouse.vendor_location_id.value}`
        : "";
    },

    distributor() {
      return this.warehouse.mars_distributor_id.key
        ? `${this.warehouse.mars_distributor_id.key} - ${this.warehouse.mars_distributor_id.value}`
        : "";
    },

    mwcBrokerSalesTerritory() {
      return this.warehouse.mwc_broker_sales_territory_id.key
        ? `${this.warehouse.mwc_broker_sales_territory_id.key} - ${this.warehouse.mwc_broker_sales_territory_id.value}`
        : "";
    },

    marsDirectSalesTerritory() {
      return this.warehouse.mars_direct_sales_territory_id.key
        ? `${this.warehouse.mars_direct_sales_territory_id.key} - ${this.warehouse.mars_direct_sales_territory_id.value}`
        : "";
    },
  },

  mounted() {
    let id = this.$route.params.id;
    if (id) {
      this.getWarehouseInfo(id);
      this.getSalesInfo(id);
    }
  },
};
</script>

<style></style>
